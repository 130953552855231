/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://i63rndrrorbhpmendcbnaeb54e.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-2dmh2hubjrfijfytc7lpl37fge",
    "aws_cloud_logic_custom": [
        {
            "name": "dataapi",
            "endpoint": "https://tl15t3qevk.execute-api.us-west-2.amazonaws.com/uat",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:99bd1122-f919-48aa-b049-765c940a52aa",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_mX7i77BvZ",
    "aws_user_pools_web_client_id": "7ru9btkhvnqhijsqvec3hdvkn2",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "fight-chance-ai-storage-73dbd8a822043-uat",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
