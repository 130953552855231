/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Badge, Flex, Icon, Text, View } from "@aws-amplify/ui-react";
export default function PatientListItem(props) {
  const { patient, overrides, ...rest } = props;
  const groupOneOnClick = useNavigateAction({
    type: "url",
    url: `${"patient/"}${patient?.id}`,
  });
  return (
    <Flex
      gap="10px"
      direction="row"
      width="unset"
      height="112px"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.25)"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "PatientListItem")}
      {...rest}
    >
      <Flex
        padding="0px 0px 0px 0px"
        width="607px"
        height="112px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "Group 4")}
      >
        <View
          width="607px"
          height="112px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="0px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "Rectangle 1")}
        ></View>
        <View
          padding="0px 0px 0px 0px"
          width="32px"
          height="43px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="33px"
          left="558px"
          {...getOverrideProps(overrides, "ArrowButton")}
        >
          <Icon
            width="32px"
            height="43px"
            viewBox={{ minX: 0, minY: 0, width: 32, height: 43 }}
            paths={[
              {
                d: "M8.72727 0L0 8.0625L14.5455 21.5L0 34.9375L8.72727 43L32 21.5L8.72727 0Z",
                fill: "rgba(0,0,0,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="0%"
            left="0%"
            right="0%"
            {...getOverrideProps(overrides, "Vector")}
          ></Icon>
        </View>
        <Badge
          width="unset"
          height="unset"
          gap="0"
          position="absolute"
          top="12px"
          left="35px"
          size="small"
          variation="default"
          children="Not Reviewed"
          {...getOverrideProps(overrides, "ReviewStatusFlag")}
        ></Badge>
        <Badge
          width="unset"
          height="unset"
          gap="0"
          position="absolute"
          top="12px"
          left="138px"
          size="small"
          variation="warning"
          backgroundColor={
            patient?.status && patient?.status == "enrolled" ? "teal" : "pink"
          }
          children={
            patient?.status && patient?.status == "enrolled"
              ? "Enrolled"
              : "Not Enrolled"
          }
          {...getOverrideProps(overrides, "EnrollmentStatusTag")}
        ></Badge>
        <View
          padding="0px 0px 0px 0px"
          width="377px"
          height="48px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="43px"
          left="35px"
          {...getOverrideProps(overrides, "Group 3")}
        >
          <View
            padding="0px 0px 0px 0px"
            width="377px"
            height="48px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="0px"
            onClick={() => {
              groupOneOnClick();
            }}
            {...getOverrideProps(overrides, "Group 1")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="800"
              color="rgba(0,0,0,1)"
              lineHeight="20px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="290.91px"
              height="21.55px"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="0px"
              left="0px"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Primary Condition or Disease&#xA;&#xA;"
              {...getOverrideProps(overrides, "Primary Condition or Disease")}
            ></Text>
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="377px"
              height="26.45px"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="21.55px"
              left="0px"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={patient?.diagnoses}
              {...getOverrideProps(
                overrides,
                "Hyperphasia, Developmental Delay"
              )}
            ></Text>
          </View>
        </View>
        <View
          padding="0px 0px 0px 0px"
          width="116px"
          height="73px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="18px"
          left="418px"
          {...getOverrideProps(overrides, "Group 2")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(13,26,38,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="116px"
            height="21.16px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="0px"
            right="0px"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={`${"Sex: "}${patient?.sex}`}
            {...getOverrideProps(overrides, "Sex: Female")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(13,26,38,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="116px"
            height="22px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="25px"
            right="0px"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={`${"Age: "}${patient?.age}`}
            {...getOverrideProps(overrides, "Age: 34")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(13,26,38,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="116px"
            height="21px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="52px"
            right="0px"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={`${"Id: "}${patient?.hadmId}`}
            {...getOverrideProps(overrides, "ID: 249")}
          ></Text>
        </View>
      </Flex>
    </Flex>
  );
}
