import { Flex } from "@aws-amplify/ui-react";
import ClinicalTrialFrameCollection from "./ui-components/ClinicalTrialFrameCollection";
import { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "./graphql/queries";
import { DataStore } from "aws-amplify";
import { ClinicalTrial } from "./models";
function Trials({searchText}) {
  // const [trials, setTrials] = useState([]);

  // const getTrials = async () => {
    // const t = await DataStore.query(ClinicalTrial);
    //const trials = await (await API.graphql(graphqlOperation(queries.listClinicalTrials))).data.listClinicalTrials.items;
    // console.log('patient----------');
    // console.log(pat);
    // console.log('patient datastore----------');
    // console.log(pat1);
    // console.log("trials----------");
    
    //console.log(t);
    //setTrials(t);
  // };
  // useEffect(() => {
  //   getTrials();
  // }, []);
  return (
    <Flex justifyContent={"center"}>
      <ClinicalTrialFrameCollection/>
    </Flex>
  );
}
export default Trials;
