// import './App.css';
import { Alert, Flex, Loader, withAuthenticator } from "@aws-amplify/ui-react";
import NavBar from "./ui-components/NavBar";
import MarketingFooter from "./ui-components/MarketingFooter";
import HeroLayout1 from "./ui-components/HeroLayout1";
import AboutUs from "./ui-components/AboutUs";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Link,
} from "react-router-dom";
import Patients from "./patients";
import PatientReview from "./patientReview";
import ReviewPatients from "./review";
import Trials from "./trials";
import Backfill from "./backfill.tsx";
import { useState, useEffect } from "react";

import { DataStore, syncExpression, Hub } from "aws-amplify";
import { Patient, ClinicalTrial, ClinicalTrialPatientLabel } from "./models";
import { Auth, AuthModeStrategyType, Amplify } from "aws-amplify";
import About from "./about";
import { Login } from "./Login";

import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);

const App = () => {
  const [dataStoreReady, setDataStoreReady] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const navigate = useNavigate();
  const [isLoggedIn, setisLoggedIn] = useState(null);
  const [user, setUser] = useState(null);

  DataStore.configure({
    syncPageSize: 300,
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
    errorHandler: (error) => {
      console.log("DataStore error" + error);
    },
    conflictHandler: async (data) => {
      // In case of conflict, discard the local update in favor of what's already on the server and log the conflict
      console.warn(" DataStore data conflict," + data);
      const modelConstructor = data.modelConstructor;
      const remoteModel = data.remoteModel;
      // const localModel = data.localModel;
      const newModel = modelConstructor.copyOf(remoteModel, (d) => {
        // todo: Remove createdAt and updatedAt, because DataStore throws an error if they exist (a bug?).
        // This is not the expected conflict resolution behavior
        // https://executivehomes.atlassian.net/wiki/spaces/CA/pages/405438465/DataStore+Data+Conflict+Scenarios
        // d.title = localModel.title;
        // @ts-ignore
        delete d.createdAt;
        // @ts-ignore
        delete d.updatedAt;
      });
      return newModel;
    },
    syncExpressions: [
      // todo: get rid of this - used when testing to prevent syncing ChangeHistory (and lots of console errors)
      syncExpression(ClinicalTrial, () => {
        return (i) => i.id.ne(""); // only active houses
      }),
      syncExpression(Patient, () => {
        return (i) => i.id.ne(""); // non-existent id
      }),
      syncExpression(ClinicalTrialPatientLabel, () => {
        return (i) => i.id.ne(""); // non-existent id
      }),
    ],
  });

  //await DataStore.start();
  const subscription = DataStore.observeQuery(ClinicalTrial, (i) =>
    i.id.ne("")
  ).subscribe((snapshot) => {
    const { items, isSynced } = snapshot;
    console.log(
      `[Snapshot] Trial item count: ${items.length}, isSynced: ${isSynced}`
    );
    setPercentage(Math.round((items.length / 2715) * 100));
  });
  const patientSubscription = DataStore.observeQuery(Patient, (i) =>
    i.id.ne("")
  ).subscribe((snapshot) => {
    const { items, isSynced } = snapshot;
    console.log(
      `[Snapshot] Patient item count: ${items.length}, isSynced: ${isSynced}`
    );
  });

  const listener = Hub.listen("datastore", async (hubData) => {
    const { event, data } = hubData.payload;
    console.log("datastore event: ", event, data);
    if (event === "ready") {
      // do something here once the data is synced from the cloud
      console.log("*******************datastore ready");
      setDataStoreReady(true);
    }
  });

  function listenToAutoSignInEvent() {
    Hub.listen("auth", ({ payload }) => {
      const { event } = payload;
      console.log("****auth event" + event);
      if (event === "autoSignIn") {
        const user = payload.data;
        console.log("autoSignIn", user);
      } else if (event === "autoSignIn_failure") {
        // redirect to sign in page
      } else if (event === "signIn") {
        const user = payload.data;
        console.log("signIn", user);
      }
    });
  }

  function signIn() {
    //withAuthenticator(Home)
    navigate("/patients");
    checkUserAuth();
  }

  async function signOut() {
    console.log("signOutClicked");
    setisLoggedIn(false);
    navigate("/");
    try {
      listener();
      subscription.unsubscribe();
      patientSubscription.unsubscribe();
      setDataStoreReady(false);
      await DataStore.stop();
      DataStore.clear();
    } catch (e) {
      console.log("error stopping datastore: ", e);
    }
    Auth.signOut();
  }
  async function checkUserAuth() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        setisLoggedIn(true);
        console.log("user is signed in");
      }
    } catch (err) {
      console.log("user is not signed in");
      setisLoggedIn(false);
    }
  }

  useEffect(() => {
    setDataStoreReady(false);
    setPercentage(0);
    checkUserAuth();
  }, []);

  return (
    <main>
      {isLoggedIn ? (
        <NavBar
          width={"100%"}
          overrides={{
            Home: { style: { cursor: "pointer" } },
            Patients: { style: { cursor: "pointer" } },
            Labels: { style: { cursor: "pointer" } },
            Trials: { style: { cursor: "pointer" } },
            "About Us": { style: { cursor: "pointer" } },
            image: {
              style: {
                cursor: "pointer",
                tooltip: "Sign Out",
              },
              onClick: () => {
                signOut();
              },
              src: "https://img.icons8.com/ios/512/private2.png",
            },
          }}
        />
      ) : (
        <NavBar
          width={"100%"}
          overrides={{
            Home: { style: { cursor: "pointer" } },
            Patients: { style: { cursor: "pointer" } },
            Labels: { style: { cursor: "pointer" } },
            Trials: { style: { cursor: "pointer" } },
            "About Us": { style: { cursor: "pointer" } },
            image: {
              style: {
                cursor: "pointer",
                tooltip: "Sign In",
                background: "lightyellow",
              },
              onClick: () => {
                signIn();
              },
              src: "https://img.icons8.com/ios/512/unlock-private.png",
            },
          }}
        />
      )}
      <Routes>
        <Route
          path="/"
          element={
            <Flex justifyContent={"center"}>
              <HeroLayout1
                overrides={{
                  image: {
                    src: "https://img.icons8.com/material-outlined/512/percentage2.png",
                  },
                  Button: {
                    style: { cursor: "pointer" },
                    onClick: () => {
                      signIn();
                    },
                  },
                }}
              />
            </Flex>
          }
        />
        <Route
          path="/patients"
          element={
            percentage !== 100 && isLoggedIn ? (
              <Flex justifyContent={"center"} height="200px">
                <Loader
                  width="300px"
                  variation="linear"
                  percentage={percentage}
                  isDeterminate
                  filledColor={"#000000"}
                />
              </Flex>
            ) : (
              <ReviewPatients loading={!dataStoreReady} />
            )
          }
        />
        <Route path="/patient/:id" element={<PatientReview />} />
        <Route path="/labels" element={<Patients />} />
        <Route path="/backfill" element={<Backfill />} />
        <Route path="/about" element={<About/>} />
        <Route path="/login" element={<Login />} />
        <Route path="/trials" element={<Trials/>} />
      </Routes>
      <br></br>
      <br></br>

      <Flex
        justifyContent="center"
        alignItems="center"
        direction="column"
        gap={0}
      >
        {!dataStoreReady ? (
          <Alert width="96%" variation="warning">
            Data Sync In Progress
          </Alert>
        ) : (
          <Alert width="96%" variation="success">
            Data Sync Complete!
          </Alert>
        )}
        <MarketingFooter height={"100px"} />
      </Flex>
    </main>
  );
};

export default App;
