import { Flex, withAuthenticator } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { Patient, ClinicalTrialPatientLabel } from "./models";
import PatientListCollection from "./ui-components/PatientListCollection";
import { DataStore } from "aws-amplify";
function Patients({ user }) {
  const [patients, setPatients] = useState([]);
  const username = user.username;
  console.log(username);
  const getPatients = async () => {
    const l = await DataStore.query(ClinicalTrialPatientLabel, (c) =>
      c.labelUser.eq(username)
    );
    console.log(l);
    var doneList = [];
    l.map((label) => {
      doneList.push(label.hadmId);
    });
    let unique = [...new Set(doneList)];

    console.log(unique);
    const p = await DataStore.query(Patient, (c) => c.reviewed.eq(true));
    console.log(p);
    var reviewedPatients = p.filter((c) => unique.includes(c.hadmId));

    console.log(reviewedPatients);
    setPatients(reviewedPatients);
  };

  useEffect(() => {
    getPatients();
    return () => {};
  }, []);

  return (
    <Flex justifyContent={"center"}>
      <PatientListCollection
        items={patients}
        overrideItems={({ item, idx }) => ({
          overrides: {
            PatientListItem: {
              as: "a",
              href: `/patient/${item.id}`,
              onClick: () => {
                console.log(item);
              },
              style: { cursor: "pointer" },
            },
            ReviewStatusFlag: {
              children: item.reviewed ? "Reviewed" : "Not Reviewed",
              backgroundColor: item.reviewed ? "lightgreen" : "lightgray",
            },
          },
        })}
      />
    </Flex>
  );
}
export default withAuthenticator(Patients);
