import { Flex, withAuthenticator } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { Patient } from "./models";
import { DataStore } from "aws-amplify";
import PatientListCollection from "./ui-components/PatientListCollection";
function ReviewPatients() {
  const [patients, setPatients] = useState([]);

  const getPatients = async () => {
    const c = await DataStore.query(Patient, (c) => c.reviewed.eq(false));
    setPatients(c);
  };

  useEffect(() => {
    getPatients();
  }, []);

  return (
    <Flex justifyContent={"center"}>
      <PatientListCollection
        items={patients}
        overrideItems={({ item, idx }) => ({
          overrides: {
            PatientListItem: {
              as: "a",
              href: `/patient/${item.id}`,
              onClick: () => {
                console.log(item);
              },
              style: { cursor: "pointer" },
            },
            ReviewStatusFlag:{ children: (item.reviewed) ? 'Reviewed' : 'Not Reviewed', backgroundColor: (item.reviewed) ? "lightgreen" : "lightgray",},
          },
        })}
      />
    </Flex>
  );
}
export default withAuthenticator(ReviewPatients);
