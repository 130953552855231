import { Flex } from "@aws-amplify/ui-react";
import AboutUs from "./ui-components/AboutUs";

function About() {
  return (
    <Flex justifyContent={"center"}>  
                <AboutUs overrides={{
                  image: {
                    src: "https://openai-labs-public-images-prod.azureedge.net/user-ywunjIVCmVcaKpBZckxRM1Mj/generations/generation-sdqGnBGnJmFJfn6XwCIvA2RP/image.webp",
                  }}}/>
    </Flex>
  );
}

export default About;
