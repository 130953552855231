/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Button, Flex, Text, View } from "@aws-amplify/ui-react";
import MyIcon from "./MyIcon";
export default function ClinicalTrialFrame(props) {
  const { clinicalTrial, overrides, ...rest } = props;
  const treatmentOnClick = useNavigateAction({
    target: "_blank",
    type: "url",
    url: `${""}${clinicalTrial?.NCTId}`,
  });
  return (
    <Flex
      gap="5px"
      direction="column"
      width="1471px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      boxShadow="1px 1px 2px rgba(0, 0, 0, 0.25)"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "ClinicalTrialFrame")}
      {...rest}
    >
      <Flex
        gap="5px"
        direction="row"
        width="unset"
        height="176px"
        justifyContent="center"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 12")}
      >
        <Flex
          padding="0px 0px 0px 0px"
          width="1043.5px"
          height="163px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "Group 7")}
        >
          <Flex
            gap="6px"
            direction="row"
            width="1043.5px"
            height="26px"
            justifyContent="flex-start"
            alignItems="flex-start"
            position="absolute"
            top="0px"
            left="0px"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Headline")}
          ></Flex>
          <Flex
            gap="6px"
            direction="column"
            width="1024px"
            height="150px"
            justifyContent="flex-start"
            alignItems="flex-start"
            position="absolute"
            top="13px"
            left="19.25px"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 8")}
          >
            <Text
              fontFamily="Inter"
              fontSize="20px"
              fontWeight="700"
              color="rgba(13,26,38,1)"
              lineHeight="20px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="989px"
              height="61px"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={clinicalTrial?.title}
              {...getOverrideProps(overrides, "TrialDescription")}
            ></Text>
            <Flex
              padding="0px 0px 0px 0px"
              width="838px"
              height="96px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Group 9")}
            >
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(92,102,112,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="612px"
                height="unset"
                gap="unset"
                alignItems="unset"
                position="absolute"
                top="0px"
                left="0px"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={`${"Trial ID: "}${clinicalTrial?.NCTId}`}
                {...getOverrideProps(overrides, "NCTId")}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(92,102,112,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="838px"
                height="unset"
                gap="unset"
                alignItems="unset"
                position="absolute"
                top="24px"
                left="0px"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={`${"Condition: "}${clinicalTrial?.condition}`}
                {...getOverrideProps(overrides, "Condition")}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(92,102,112,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="838px"
                height="unset"
                gap="unset"
                alignItems="unset"
                position="absolute"
                top="48px"
                left="0px"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="View on ClinicalTrials.Gov"
                onClick={() => {
                  treatmentOnClick();
                }}
                {...getOverrideProps(overrides, "Treatment")}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(92,102,112,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="612px"
                height="unset"
                gap="unset"
                alignItems="unset"
                position="absolute"
                top="72px"
                left="0px"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={`${"Start Date: "}${clinicalTrial?.startDate}`}
                {...getOverrideProps(overrides, "StartDate")}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
        <View
          width="425px"
          height="128px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "Frame 418")}
        >
          <View
            padding="0px 0px 0px 0px"
            width="355px"
            height="108px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="20px"
            left="29.75px"
            {...getOverrideProps(overrides, "Group 11")}
          >
            <Button
              width="152px"
              height="44px"
              position="absolute"
              top="0%"
              bottom="59.26%"
              left="57.18%"
              right="0%"
              backgroundColor="rgba(149,4,4,1)"
              size="large"
              isDisabled={false}
              variation="primary"
              children="No"
              {...getOverrideProps(overrides, "NoButton")}
            ></Button>
            <Button
              width="152px"
              height="44px"
              position="absolute"
              top="59.26%"
              bottom="0%"
              left="27.04%"
              right="30.14%"
              backgroundColor="rgba(64,170,191,1)"
              size="large"
              isDisabled={false}
              variation="primary"
              children="Unknown"
              {...getOverrideProps(overrides, "UnknownButton")}
            ></Button>
            <Button
              width="152px"
              height="44px"
              position="absolute"
              top="0%"
              bottom="59.26%"
              left="0%"
              right="57.18%"
              backgroundColor="rgba(67,168,84,1)"
              size="large"
              isDisabled={false}
              variation="primary"
              children="Yes"
              {...getOverrideProps(overrides, "YesButton")}
            ></Button>
          </View>
        </View>
      </Flex>
      <Flex
        gap="16px"
        direction="row"
        width="1469px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Article")}
      >
        <MyIcon
          width="24px"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          type="arrow-right"
          {...getOverrideProps(overrides, "MyIcon")}
        ></MyIcon>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="1340px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={clinicalTrial?.summary}
          {...getOverrideProps(overrides, "TrialSummary")}
        ></Text>
      </Flex>
      <Flex
        gap="10px"
        direction="column"
        width="1471px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="40px 140px 40px 140px"
        backgroundColor="rgba(239,240,240,1)"
        {...getOverrideProps(overrides, "Frame 417")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 11")}
        >
          <Flex
            gap="24px"
            direction="row"
            width="1160px"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 136")}
          >
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 139")}
            >
              <Text
                fontFamily="Inter"
                fontSize="20px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="25px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Inclusion Criteria"
                {...getOverrideProps(overrides, "Inclusion")}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="14px"
                fontWeight="400"
                color="rgba(48,64,80,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={clinicalTrial?.inclusionCriteria}
                {...getOverrideProps(overrides, "InclusionText")}
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="529px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 140")}
            >
              <Text
                fontFamily="Inter"
                fontSize="20px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="25px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Exclusion Criteria"
                {...getOverrideProps(overrides, "Exclusion Criteria")}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="14px"
                fontWeight="400"
                color="rgba(48,64,80,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={clinicalTrial?.exclusionCriteria}
                {...getOverrideProps(overrides, "ExclusionText")}
              ></Text>
            </Flex>
            <Flex
              padding="0px 0px 0px 0px"
              width="152px"
              height="128px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Group 10")}
            >
              <Flex
                gap="10px"
                direction="column"
                width="152px"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                position="absolute"
                top="0px"
                left="0px"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 142")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="700"
                  color="rgba(13,26,38,1)"
                  lineHeight="25px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Other Criteria"
                  {...getOverrideProps(overrides, "Other Criteria")}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="14px"
                  fontWeight="400"
                  color="rgba(48,64,80,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="181px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={`${"Minimum Age: "}${clinicalTrial?.minAge}`}
                  {...getOverrideProps(overrides, "Minimum Age: 18")}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="14px"
                  fontWeight="400"
                  color="rgba(48,64,80,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="181px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={`${"Maximum Age: "}${clinicalTrial?.maxAge}`}
                  {...getOverrideProps(overrides, "Maximum Age: 75")}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="14px"
                  fontWeight="400"
                  color="rgba(48,64,80,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="181px"
                  height="25px"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={`${"Sex: "}${clinicalTrial?.sex}`}
                  {...getOverrideProps(overrides, "Gender: Male")}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
