import { Button, Divider, Flex, Heading, withAuthenticator } from "@aws-amplify/ui-react";

import {
  DataStore,
  Amplify,
  API,
  graphqlOperation,
  Predicates,
} from "aws-amplify";
import { Patient, ClinicalTrial, ClinicalTrialPatientLabel } from "./models";

import React, { useState, CSSProperties, useEffect } from "react";
import * as mutations from "./graphql/mutations";
import awsconfig from "./aws-exports";
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from "react-papaparse";
import { fileAccepted } from "react-papaparse/dist/utils";


const GREY = "#CCC";
const GREY_LIGHT = "rgba(255, 255, 255, 0.4)";
const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = "#686868";

const styles = {
  zone: {
    alignItems: "center",
    borderWidth: 2,
    borderStyle: "dashed",
    borderColor: GREY,
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: 20,
  } as CSSProperties,
  file: {
    background: "linear-gradient(to bottom, #EEE, #DDD)",
    borderRadius: 20,
    display: "flex",
    height: 120,
    width: 120,
    position: "relative",
    zIndex: 10,
    flexDirection: "column",
    justifyContent: "center",
  } as CSSProperties,
  info: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: "0.5em",
    justifyContent: "center",
    display: "flex",
  } as CSSProperties,
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: "0.5em",
  } as CSSProperties,
  progressBar: {
    bottom: 14,
    position: "absolute",
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  zoneHover: {
    borderColor: GREY_DIM,
  } as CSSProperties,
  default: {
    borderColor: GREY,
  } as CSSProperties,
  remove: {
    height: 23,
    position: "absolute",
    right: 6,
    top: 6,
    width: 23,
  } as CSSProperties,
};

const config = {
  header: true,
};


function getData() {
  const apiName = 'dataapi';
  const path = '/api/ping';
  const myInit = {
    headers: {} // OPTIONAL
  };

  return API.get(apiName, path, myInit);
}

// (async function() {
//   console.log("in async function");
  
// })();


const apiName = 'dataapi';
const path = '/api/ping';
const myInit = {
  headers: {}, // OPTIONAL
  response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  queryStringParameters: {
    name: 'param' // OPTIONAL
  }
};

API.get(apiName, path, myInit)
  .then((response) => {
    // Add your code here
    console.log(response);
  })
  .catch((error) => {
    console.log(error);
  });

async function loadPatient(results: any) {
  var processedCnt = 0;
  var totalCnt = results.length;
  //var totalCnt = 10;

  console.log("Deleting existing data...");
  await DataStore.delete(Patient, Predicates.ALL);
  for (var b = 0; b < totalCnt / 200; b++) {
    for (var i = processedCnt; i < 200 * (b + 1); i++) {
      if (processedCnt === totalCnt - 1) {
        break;
      }
      var item = results[i];
      processedCnt++;
      console.log("in load patient function");
      console.log(item);
      try {
        var label = DataStore.save(
          new Patient({
            hadmId: parseInt(item.hadmid),
            sex: item.sex,
            age: parseInt(item.age),
            diagnoses: item.diagnoses,
            potentialTrials: Array.from(
              JSON.parse(item.potentialTrials.replace(/'/g, '"'))
            ),
            allergies: item.allergies,
            summary: item.summary,
            status: "Not Enrolled",
            subjectId: parseInt(item.subjectId),
            reviewed: false,
          })
        );
        console.log(label);
      } catch (e) {
        console.log(e);
      }
    }
  }
}

async function loadClinicalTrial(trials: any) {
  var processedCnt = 0;
  var totalCnt = trials.length;
  //var totalCnt = 10;
  console.log("Deleting existing data...");
  await DataStore.delete(ClinicalTrial, Predicates.ALL);
  console.log("Populating data...");
  for (var b = 0; b < totalCnt / 200; b++) {
    for (var i = processedCnt; i < 200 * (b + 1); i++) {
      if (processedCnt === totalCnt - 1) {
        break;
      }
      var trial = trials[i];
      processedCnt++;
      console.log(trial + ": " + i);
      // if (trial.NCTId === null) {
      //   console.log("No NCTID");
      //   return;
      // }
      try {
        var completeDate = new Date(trial.CompletionDate)
          .toISOString()
          .substring(0, 10);
        var startDate = new Date(trial.StartDate)
          .toISOString()
          .substring(0, 10);

        // var record = new ClinicalTrial({
        //   NCTId: trial.NCTId,
        //   title: trial.BriefTitle,
        //   condition: trial.Condition,
        //   summary: trial.BriefSummary,
        //   intervention: trial.Intervention,
        //   startDate: startDate,
        //   endDate: completeDate,
        //   status: trial.OverallStatus,
        //   inclusionCriteria: trial.InclusionCriteria,
        //   exclusionCriteria: trial.ExclusionCriteria,
        //   sex: trial.Gender,
        //   maxAge: parseInt(trial.MaximumAgeYears),
        //   minAge: parseInt(trial.MinimumAgeYears),
        //   organization: trial.OrgFullName,
        //   _version: 1,
        // });

        // var result = await API.graphql(graphqlOperation(mutations.createClinicalTrial, { input: record }))

        var result = DataStore.save(
          new ClinicalTrial({
            NCTId: trial.NCTId,
            title: trial.BriefTitle,
            condition: trial.Condition,
            summary: trial.BriefSummary.substring(0, 500000),
            intervention: trial.Intervention.substring(0, 100),
            startDate: startDate,
            endDate: completeDate,
            status: trial.OverallStatus,
            inclusionCriteria: trial.InclusionCriteria.substring(0, 500000),
            exclusionCriteria: trial.ExclusionCriteria.substring(0, 500000),
            sex: trial.Gender,
            maxAge: parseInt(trial.MaximumAgeYears),
            minAge: parseInt(trial.MinimumAgeYears),
            organization: trial.OrgFullName,
          })
        );
        console.log(result);
      } catch (e) {
        console.log("----------Caught error" + e);
      }
    }
  }
}

async function deleteExistingPatients() {
  console.log("Truncating Patients");

  const patients = await DataStore.query(Patient);
  const patientMutation: any = patients.map((patient, i) => {
    //return `mutation${i}: deletePatient(input: {id: "${patient.id}"}) {id}`;
    // if (patient._version == null) {
    //   patient._version = 0;
    // }
    // return `mutation${i}: deletePatient(input: {id: "${patient.id}", _version:${patient._version}}) {id}`;
  });
  // patients.forEach((patient, i) => {
  //   console.log(patient);
  //   DataStore.delete(patient);
  // });

  //const patients = await DataStore.query(Patient,Predicates.ALL, {page: 0, limit: 200});

  console.log(patientMutation);
  if (patientMutation.length > 0) {
    const results = await API.graphql(
      graphqlOperation(`
        mutation batchMutation {
          ${patientMutation}
        }
      `)
    );
  }
}
async function deleteExistingLabels() {
  console.log("Truncating Labels");
  const labels = await DataStore.delete(
    ClinicalTrialPatientLabel,
    Predicates.ALL
  );
  console.log("Completed Truncating Labels");
}

async function callAPI() {
  console.log("Calling API");
  const response = await getData();
  console.log(response);
  console.log("Completed Calling API");
}


async function loadUpload(results: any, destTable: string) {
  const { data } = results;
  if (destTable === "patient") {
    // deleteExistingPatients();
    console.log("Loading Patients");
    loadPatient(results.data);
  } else if (destTable === "clinicaltrial") {
    console.log("Loading Clinical Trials");
    loadClinicalTrial(results.data);
  }
}

function Backfill() {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [count, setCount] = useState(10);
  const [destinationTable, setDestinationTable] = useState("patient");
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );
  const handleChange = (event: any) => {
    var value = event.target.value;
    setDestinationTable(value);
    console.log(destinationTable);
  };

  const config = { header: true };
  const [patients, setPatients] = useState<Patient[]>();
  const [clinicalTrials, setClinicalTrials] = useState<ClinicalTrial[]>();

  const getPatients = async () => {
    var c = await DataStore.query(Patient);
    //console.log(c);
    setPatients(c);
  };

  const getClinicalTrials = async () => {
    const c = await DataStore.query(ClinicalTrial);
    setClinicalTrials(c);
  };

  useEffect(() => {
    getPatients();
    getClinicalTrials();
  }, []);

  
  return (
    <>
      <Flex justifyContent={"center"}>
        <h1>DataStore Backfill Utility</h1>
        <br />
        </Flex>
        <Flex justifyContent={"center"}>
        <form>
          <label htmlFor="destinationTable">Destination Table: </label>
          <input type="text" onChange={handleChange} />
        </form>
      </Flex>
      <br/>

      <CSVReader
        config={config}
        onUploadAccepted={(results: any) => {
          console.log("---------------------------");
          console.log(results);
          console.log("---------------------------");
          console.log("Loading " + destinationTable);
          loadUpload(results, destinationTable);
          //loadUpload(results, "clinicaltrial");
          //results.data.forEach(loadUpload);
          console.log("---------------------------");
          setZoneHover(false);
        }}
        onDragOver={(event: DragEvent) => {
          event.preventDefault();
          setZoneHover(true);
        }}
        onDragLeave={(event: DragEvent) => {
          event.preventDefault();
          setZoneHover(false);
        }}
      >
        {({
          getRootProps,
          acceptedFile,
          ProgressBar,
          getRemoveFileProps,
          Remove,
          Config,
        }: any) => (
          <>
            <div
              {...getRootProps()}
              style={Object.assign(
                {},
                styles.zone,
                zoneHover && styles.zoneHover
              )}
            >
              {acceptedFile ? (
                <>
                  <div style={styles.file}>
                    <div style={styles.info}>
                      <span style={styles.size}>
                        {formatFileSize(acceptedFile.size)}
                      </span>
                      <span style={styles.name}>{acceptedFile.name}</span>
                    </div>
                    <div style={styles.progressBar}>
                      <ProgressBar />
                    </div>
                    <div
                      {...getRemoveFileProps()}
                      style={styles.remove}
                      onMouseOver={(event: Event) => {
                        event.preventDefault();
                        setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                      }}
                      onMouseOut={(event: Event) => {
                        event.preventDefault();
                        setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                      }}
                    >
                      <Remove color={removeHoverColor} />
                    </div>
                  </div>
                </>
              ) : (
                "Drop CSV file here or click to upload."
              )}
            </div>
          </>
        )}
      </CSVReader>
      <br />
      <br />
      <h2 style={{ textAlign: "center" }}>Other Utilities</h2>
      <Flex justifyContent={"center"}>
        <Button onClick={() => deleteExistingLabels()}>Delete Labels</Button>
      </Flex>
      <Flex justifyContent={"center"}>
        <Button onClick={() => callAPI()}>API Request</Button>
      </Flex>
      <Flex justifyContent={"center"}>
        <h3>Trial Count: {clinicalTrials?.length}</h3>
        <h3>Patient Count: {patients?.length}</h3>
      </Flex>
    </>
  );
}

export default withAuthenticator(Backfill);