import PatientSummaryFrame from "./ui-components/PatientSummaryFrame";
import {
  Button,
  Divider,
  Flex,
  Heading,
  Loader,
  ScrollView,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import ClinicalTrialCollection from "./ui-components/ClinicalTrialCollection";
import { DataStore, Amplify } from "aws-amplify";
import { Patient, ClinicalTrial, ClinicalTrialPatientLabel } from "./models";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ConfettiExplosion from 'react-confetti-explosion'

import { API, graphqlOperation } from "aws-amplify";
import * as queries from "./graphql/queries";

function PatientReview({user,loading}) {
  const { id } = useParams();
  const [trials, setTrials] = useState([]);
  const [patient, setPatient] = useState([]);
  const [labels, setLabels] = useState([]);
  const [trialsLeftCount, setTrialsLeftCount] = useState(null);
  const [reviewComplete, setReviewComplete] = useState(false);
  const [trialsReviewed, setTrialsReviewed] = useState(0);
  const [trialCount, setTrialCount] = useState(null);

  user = user.username;

  const getPatient = async () => {
    const pat = await DataStore.query(Patient, id);
    //const pat = await (await API.graphql(graphqlOperation(queries.getPatient, {id: id}))).data.getPatient;
    // console.log('patient----------');
    // console.log(pat);
    // console.log('patient datastore----------');
    // console.log(pat1);
    // const trials = []
    // for(var i = 0; i < pat.potentialTrials.length; i++){
    //   var trialId = pat.potentialTrials[i].toString();
    //   console.log('trialId----------');
    //   console.log(trialId);
    //   var c = await (await API.graphql(graphqlOperation(queries.listClinicalTrials,{ filter:{ NCTId:{ eq: "NCT01866657"}}}))).data.listClinicalTrials.items;
    //   trials.push(c);
    //   console.log('trials----------');
    //   console.log(trials);

    // }

    console.log("patient----------");
    console.log(pat);
    setPatient(pat);

    console.log("labels----------");
    const l = await DataStore.query(ClinicalTrialPatientLabel, (c) =>
      c.and((c) => [c.labelUser.eq(user), c.hadmId.eq(patient.hadmId)])
    );
    var doneList = [];
    l.map((label) => {
      doneList.push(label.trialId);
    });
   // console.log(doneList);
    setLabels(doneList);

    console.log("trials object----------");
    var trials = await DataStore.query(ClinicalTrial);
    var trialIdList = pat.potentialTrials; //.filter((trials) => !trials.includes(doneList));
    
    //console.log(trialIdList);
    // var trialGraph = [];
    // if (trialIdList.length > 0) {
    //   for (var i = 0; i < trialIdList.length; i++) {
    //     console.log('getting trial for ' + trialIdList[i]);
    //     var t = await API.graphql(
    //       graphqlOperation(queries.clinicalTrialsByNCTId, {
    //         NCTId: trialIdList[i],
    //       })
    //     );
    //     console.log(t.data.clinicalTrialsByNCTId.items.filter((t) => t._deleted != true));
    //     trialGraph.push(t);
    //   }
    // }
    //console.log(trialGraph);

    //getTrials();

    var reviewTrials = trials.filter(
      (trials) =>
        pat.potentialTrials.includes(trials.NCTId) &&
        !labels.includes(trials.NCTId)
    );
    var reviewedTrials = trials.filter(
      (trials) =>
        pat.potentialTrials.includes(trials.NCTId) &&
        labels.includes(trials.NCTId)
    );

    
    setTrials(reviewTrials);
    setTrialsLeftCount(reviewTrials.length);
    setTrialsReviewed(reviewedTrials.length);
    setTrialCount(trialIdList.length);

    console.log("trialsLeftCount----------");
    console.log("to review " + reviewTrials.length);
    console.log("reviewed " + reviewedTrials.length);
    console.log(trialsLeftCount);

    if ((trialCount-trialsReviewed) < 1 && trialsReviewed != null && trialCount != null) {
      console.log("**********no trials left!!!!");
      setReviewComplete(true);
      await DataStore.save(
        Patient.copyOf(pat, (updated) => {
          updated.reviewed = true;
        })
      );
    } else {
      setReviewComplete(false);
    }
    // var trialArray = [];
    // console.log("trial ------" + pat.potentialTrials);
    // for (var i = 0; i < pat.potentialTrials.length; i++) {
    //   var variables = {
    //     filter: {
    //       NCTId: {
    //         eq: pat.potentialTrials[i],
    //       },
    //     },
    //   };

    //   var t = await API.graphql({
    //     query: queries.listClinicalTrials,
    //     variables: variables,
    //   });
    //   console.log('t----------');
    //   console.log(t);
    //   trialArray.push(t.data.listClinicalTrials.items);
    //   console.log(trialArray);
    // }

    // var trialArray = [];
    // console.log("trial ------" + pat.potentialTrials);
    // for (var i = 0; i < pat.potentialTrials.length; i++) {
    //   var variables = {
    //     filter: {
    //       NCTId: {
    //         eq: pat.potentialTrials[i],
    //       },
    //     },
    //   };

    //   var t = await API.graphql(
    //     graphqlOperation(queries.listClinicalTrials,{filter: { NCTId: { eq: pat.potentialTrials[i] } }}));
    //   console.log('t----------');

    // console.log(t);
    // //   trialArray.push(t.data.listClinicalTrials);

    //  }
    // console.log('trialArray----------');
    // console.log(trialArray);
    // setTrials(trialArray);

    //getLabels();
  };

  const getLabels = async () => {
    //const l = await DataStore.query(ClinicalTrialPatientLabel, {filter:{and[eq:{labelUser: user},eq:{hadmId: patient.hadmId}]}});
    const l = await DataStore.query(ClinicalTrialPatientLabel, (c) =>
      c.and((c) => [c.labelUser.eq(user), c.hadmId.eq(patient.hadmId)])
    );
    var doneList = [];
    l.map((label) => {
      doneList.push(label.trialId);
    });
    
    console.log("labels----------");
    console.log(doneList);
    setLabels(doneList);
  };

  const getTrialByNCTId = async (trialId) => {
    var t = await API.graphql(
      graphqlOperation(queries.clinicalTrialsByNCTId, { NCTId: trialId })
    ); //.filter((trial) => (!trial._deleted || trial._deleted == null));
    console.log("t----------");
    console.log(t);
    return t;
  };

  const getTrials = async () => {
    var c = await DataStore.query(ClinicalTrial);

    // const trials = [];
    // for (var i = 0; i < patient.potentialTrials.length; i++) {
    //   var trialId = patient.potentialTrials[i];
    //   console.log("trialId----------");
    //   console.log(trialId);
    //   var t = await API.graphql(
    //     graphqlOperation(queries.clinicalTrialsByNCTId, { NCTId: trialId })
    //   );
    //   console.log("t----------");
    //   console.log(t);

    //   if (t.items) {
    //     trials.push(t);
    //   }
    //   console.log("graph trials----------");
    //   console.log(trials);
    // }
    // console.log("trials----------");
    // console.log(patient.potentialTrials);
    // console.log("labels----------");
    // console.log(labels);
    // if (patient.potentialTrials && labels) {
    //   c = c.filter(trials=> patient.potentialTrials.includes(trials.NCTId)&& !labels.includes(trials.NCTId))
    // }
    //var filter = patient.potentialTrials.map((trial) => `{ NCTId: { eq: ${trial} } `).join(',');
    // console.log('filter----------');
    // console.log(patient.potentialTrials);
    // console.log(filter);
    ///////////
    // var trialArray = []
    // console.log('trial ------' + patient.potentialTrials);
    // for(var i = 0; i < patient.potentialTrials.length; i++){
    //   var variables = {
    //     filter: {
    //       NCTId: {
    //         eq: patient.potentialTrials[i]
    //       }
    //     }
    //   };
    //   var t =  await API.graphql(graphqlOperation({query: queries.listClinicalTrials, variables:variables}));
    //   trialArray.push(t)
    //   console.log(trialArray);
    // }
    /////////
    //const c = await (await API.graphql(graphqlOperation(queries.listClinicalTrials,{limit: 1000}))).data.listClinicalTrials.items;
    //console.log("initial trials----------");
    //console.log(c);

    //setTrials(c);
  };

  const updateTrials = async () => {
    await getLabels();
    setTrials(
      trials.filter(
        (trials) =>
          patient.potentialTrials.includes(trials.NCTId) &&
          !labels.includes(trials.NCTId)
      )
    );
  };

  function handleClick(username, trialId, patientId, isMatch) {
    console.log("LABLELING "+ trialId + " for " + patientId + " as " + isMatch + " for " + username);
    var label = DataStore.save(
      new ClinicalTrialPatientLabel({
        labelUser: username,
        hadmId: patientId,
        trialId: trialId,
        isMatch: isMatch,
      })
    );
    //setTrialsLeftCount(trialsLeftCount - 1);
    setTrialsReviewed(trialsReviewed+1);
  }

  useEffect(() => {
    getPatient();
    return () => {
      console.log("unmounting...");
    };
  }, [trialsReviewed]);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Heading level={4} textAlign={"center"}>
        Remaining: {trialCount - trialsReviewed}, Reviewed:{" "}
        {trialsReviewed}
      </Heading>
      <Flex direction="row" justifyContent={"center"}>
        <PatientSummaryFrame patient={patient} />
      </Flex>
      <div>
        <Flex direction="row" justifyContent={"center"}>
        <ScrollView width="1400px" height="300px" padding={"large"}>
          {patient.summary}
        </ScrollView>
        </Flex>
        <Divider width="100%" />
      </div>
      <br></br>
      <Heading level={4} textAlign={"center"}>
        Candidate Clinical Trials
      </Heading>
      <Flex direction="row" justifyContent={"center"}>
        {!reviewComplete ? (
          <ClinicalTrialCollection
            items={trials.filter(
              (trials) =>
                patient.potentialTrials.includes(trials.NCTId) &&
                !labels.includes(trials.NCTId)
            )}
            overrideItems={({ item, idx }) => ({
              overrides: {
                YesButton: {
                  as: "button",
                  onClick: () => {
                    handleClick(user, item.NCTId, patient.hadmId, true);
                  },
                },
                NoButton: {
                  as: "button",
                  onClick: () => {
                    handleClick(user, item.NCTId, patient.hadmId, false);
                  },
                },
                UnknownButton: {
                  as: "button",
                  onClick: () => {
                    handleClick(user, item.NCTId, patient.hadmId, null);
                  },
                },
              },
            })}
          />
        ) : (
          <h1> No more trials left to label!</h1>
        )}
        {reviewComplete && <ConfettiExplosion />}
      </Flex>
    </>
  );
}

export default withAuthenticator(PatientReview);
